<template>
  <v-sheet
    :height="height"
    outlined
    color="transparent"
    class="calendar-view rounded mb-4"
    v-resize="setCalendarWidth"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      absolute
      color="secondary"
      class="my-0"
    />
    
    <v-expand-transition>
      <v-alert
        v-if="views.selection.toggle"
        color="transparent"
        :icon="icons.calendar"
        class="calendar-selection pt-6 pb-2 mb-0"
      >
        <p class="body-1">
          <b class="accent--text">Data: {{ readableDate }}</b>
        </p>
        <p class="body-1">
          Local: <a href="geo:0,0?q=R.+Viêira+Ferreira,+55+-+Bonsucesso">Rua Viêira Ferreira, 55 – Bonsucesso</a>
        </p>
        <v-divider v-show="changeEnabled" />
        <v-card-actions v-show="changeEnabled">
          <v-spacer />
          <v-btn
            v-show="!views.calendar.toggle"
            color="grey"
            text
            :disabled="loading"
            class="btn-cancel text--secondary"
            @click="toggleCancel(true)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-show="!views.calendar.toggle"
            text
            :disabled="loading"
            class="btn-change text--secondary"
            @click="changeSelection()"
          >
            Remarcar
          </v-btn>
          <v-btn
            v-show="views.calendar.toggle"
            text
            class="btn-close text--secondary"
            @click="toggleView('calendar', false)"
          >
            Voltar
          </v-btn>
        </v-card-actions>
      </v-alert>
      <v-container 
        v-if="views.calendar.toggle&&hasSlots"
        fluid 
        ref="calendar-grid"
        class="calendar-grid pa-0"
      >
        <div 
          class="calendar-scroll-container snap-x"
        >
          <div 
            class="header"
            :style="{ 'width': views.calendar.width }"
          >
            <v-row
              no-gutters
            >
              <v-col
                v-for="(day, j) in hAxis"
                :key="'header-'+j"
                class="slot-col snap-child text-center"
                :style="{ 'width': views.calendar.width / span.slots }"
              >
                <h6 class="overline mt-2 text--secondary">
                  {{ $moment(day).format('ddd') }}
                </h6>
                <h5 class="subtitle-1 mt-1 mb-3">
                  {{ $moment(day).format('dd') }}
                </h5>
              </v-col>
            </v-row>
          </div>
          <div 
            v-scroll.self="onScrollCalendar"
            ref="calendar-scroll"
            class="calendar-scroll snap-y"
            :style="{ 'width': views.calendar.width }"
            style="max-height: 50vh;"
          >
            <v-row
              v-for="(hour, i) in vAxis"
              :key="i"
              no-gutters
            >
              <v-col
                v-for="(day, j) in hAxis"
                :key="j"
                class="slot-col snap-child"
              >
                <v-card
                  :height="tileHeight"
                  :class="[{ 'available': !controller[day+' '+hour].disabled }, (controller[day+' '+hour].selected||controller[day+' '+hour].hasOwnProperty('highlight')&&controller[day+' '+hour].highlight ? 'white' : controller[day+' '+hour].color)+'--text']"
                  :color="controller[day+' '+hour].selected ? 'secondary' : controller[day+' '+hour].hasOwnProperty('highlight')&&controller[day+' '+hour].highlight ? controller[day+' '+hour].color : 'transparent'"
                  :elevation="controller[day+' '+hour].disabled ? 0 : 1"
                  class="tile d-flex flex-column justify-center text-center"
                  @click="confirmSlot(day+' '+hour, controller[day+' '+hour])"
                >
                  <span class="subtitle-2">
                    {{ hour }}h
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-fab-transition>
          <v-btn
            v-show="hasPrev"
            class="btn-prev secondary--text"
            color="grey darken-3"
            fab
            small 
            absolute
            top
            left
            elevation="4"
            @click="prevDay"
          >
            <v-icon>{{ icons.prev }}</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            v-show="hasNext"
            class="btn-next secondary--text"
            color="grey darken-3"
            fab
            small
            absolute
            top
            right
            elevation="4"
            @click="nextDay"
          >
            <v-icon>{{ icons.next }}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-container>
    </v-expand-transition>
    <v-fab-transition>
      <v-btn
        v-show="confirm.button"
        fab
        small
        fixed
        bottom
        right
        elevation="4"
        color="primary"
        class="btn-confirm"
        @click="toggleConfirm(true);"
      >
        <v-icon>{{ icons.send }}</v-icon>
      </v-btn>
    </v-fab-transition>
    <aside
      v-if="grid"
      ref="grid-hint"
      class="grid-hint text-left"
    >
      <span 
        v-for="h in vAxis"
        :key="'row-hint-'+h"
        :style="{ 'line-height': (tileHeight-8)+'px' }"
        class="row-hint text-caption text--disabled"
      >
        {{ h }}
      </span>
    </aside>

    <v-dialog
      v-model="confirm.toggle"
      transition="slide-y-reverse-transition"
      persistent
      open-delay="250"
    >
      <v-card color="grey darken-3">
        <v-card-title>Confirmação</v-card-title>
        <v-card-text class="py-4">
          <p class="body-1">
            {{ question }} <b>{{ readableDate }}</b>?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            text
            :disabled="confirm.loading"
            @click="toggleConfirm(false);"
          >
            Alterar
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="confirm.loading"
            @click="selectSlots"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cancel.toggle"
      transition="slide-y-reverse-transition"
      persistent
      :open-delay="250"
    >
      <v-card color="grey darken-3">
        <v-card-title>Cancelar agendamento</v-card-title>
        <v-card-text class="py-4">
          <p class="body-1">
            Tem certeza que deseja cancelar seu agendamento <b>{{ readableDate }}</b>?
          </p>
          <p class="body-1">
            <b>Atenção:</b> ao cancelar, este horário ficará disponível para outros Parceiros.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey lighten-1"
            text
            :disabled="cancel.loading"
            @click="toggleCancel(false);"
          >
            Voltar
          </v-btn>
          <v-btn
            color="error"
            text
            :loading="cancel.loading"
            @click="cancelSlot"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<style>

  .v-sheet.calendar-view {
    border-color: var(--secondary-border) !important;
  }

  .calendar-view {
    position: relative;
  }
  .calendar-view .calendar-scroll {
    position: relative;
  }

  .calendar-view .tile {
    position: relative;
    margin: 4px;
    border-radius: 2px;
    opacity: .5;
  }
  .calendar-view .tile.available {
    opacity: 1;
  }
    
  .calendar-view .vAxis .caption {
    display: block;
    margin-top: -4.075px;
    visibility: hidden;
  }
  .calendar-view .vAxis .caption.visible {
    visibility: visible;
  }

  .calendar-view .step .caption {
    display: inline-block;
    line-height: .875rem;
  }

  .calendar-selection + .calendar-grid {
    border-top: 1px solid var(--secondary-border) !important;
  }

  .calendar-view .row:not(:last-of-type), .calendar-view .header {
    border-bottom: 1px solid var(--secondary-border) !important;
  }
  .calendar-view .col:not(:last-of-type) {
    border-right: 1px solid var(--secondary-border) !important;
  }

  .calendar-view .grid-hint {
    position: absolute;
    top: 66px;
    left: -7px;
    max-width: 36px;
    max-height: 50vh;
    overflow: hidden;
  }
  .calendar-view .grid-hint .row-hint {
    display: inline-block;
    background: var(--mobees-black);
    border-bottom: 1px solid transparent !important;
    border-radius: 2px;
    opacity: .8;
    padding: 0 6px;
    margin: 8px 0;
  }

  .row + .row {
    margin: 0;
  }


</style>

<script>
  import { mdiHelpCircleOutline, mdiChevronRight, mdiChevronLeft, mdiCalendarCheck, mdiClose, mdiSend } from '@mdi/js';
  import _ from 'lodash';

  export default {
    props: {
      slots: {
        type: Object,
        default: () => {
          return null;
        }
      },
      selected: {
        type: Object,
        default: () => {
          return null;
        }
      },
      tile: {
        type: Number,
        default: 36
      },
      height: {
        type: String,
        default: 'auto'
      },
      loading: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      cancelable: {
        type: Boolean,
        default: true
      },
      grid: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
        next: mdiChevronRight,
        prev: mdiChevronLeft,
        calendar: mdiCalendarCheck,
        close: mdiClose,
        send: mdiSend
      },
      pointer: null,
      span: {
        slots: 0,
        view: 5,
      },
      first: {
        hour: 24,
        day: null,
      },
      last: {
        hour: 0,
        day: null,
      },
      controller: {},
      views: {
        calendar: {
          toggle: true,
          updating: false,
          width: '100%',
          shown: false,
          scroll: {
            top: 0,
            left: 0
          }
        },
        selection: {
          toggle: true,
        },
      },
      confirm: {
        toggle: false,
        loading: false,
        button: false
      },
      cancel: {
        toggle: false,
        loading: false
      },
    }),

    computed: {
      hAxis () {
        const cols = this.span.slots > this.span.view ? this.span.slots : this.span.view;
        const base = [...Array(cols).keys()];
        return _.map(base, d => this.$moment(this.first.day).add(d, 'days').format('YYYY-MM-DD'));
      },
      vAxis () {
        return _.filter(_.map([...Array(24).keys()], (h) => {
          return h;
        }), h => {
          // console.log(h, this.first.hour, this.last.hour);
          return h >= this.first.hour && h <= this.last.hour;
        });
      },
      tileWidth () {
        return (this.views.calendar.width / this.span.slots) * .75;
      },
      tileHeight () {
        return this.last.hour - this.first.hour > 12 ? 28 : 36;
      },
      hasNext () {
        const next = this.$moment(this.pointer).add(this.span.view - 1, 'days');
        return false //this.$moment(next).isBefore(this.last.day);
      },
      hasPrev () {
        const prev = this.pointer;
        return false //this.$moment(prev).isAfter(this.first.day);
      },
      hasSlots () {
        return !!this.slots && _.size(this.slots)>0;
      },
      changeEnabled () {
        return this.cancelable && !!this.selected && this.hasSlots && this.$moment(this.selected.start).diff(this.$moment(), 'hours') >= 2;
      },

      question () {
        return this.multiple ? 'Deseja confirmar os dias/horários ' : 'Deseja confirmar o agendamento para'
      },
      readableDate () {
        let selection = _.filter(this.controller, 'selected');
        let message = '';
        if (this.multiple) {
          selection = _.groupBy(selection, hour => this.$moment(hour.start).format('YYYY-MM-DD'));
          selection = _.mapValues(selection, day => {
            return _.keyBy(day, hour => this.$moment(hour.start).hour());
          });
          message = _.reduce(selection, (text, hours, day) => {
            const d = this.$moment(day);
            hours = _.map(hours, (hour, h) => parseInt(h)).sort((a,b) => (a-b));
            const intervals = _.reduce(hours, (comp, h, i) => {
              h = parseInt(h);
              if (comp.last==null) {
                comp.last = h;
                comp.start = h;
                comp.text += hours.length==1 ? this.multiple ? h+'-'+(h+1)+'h' : h+'h' : h;
              }else {
                if (comp.last+1<h) {
                  if (comp.last==comp.start) {
                    comp.text += '-'+(h+1)+'h';
                  }else{
                    comp.text += '-'+(comp.last+1)+'h';
                  }
                  comp.text += ', '+(hours.length==i+1 ? h+'-'+(h+1)+'h' : h);
                  comp.start = h;
                }else if (hours.length==i+1) {
                  comp.text += '-'+(h+1)+'h';
                }
                comp.last = h;
              }
              return comp;
            }, { text: '', start: null, last: null });
            text += ' dia '+(d.date())+'/'+(d.month()+1)+': '+intervals.text+';';
            return text;
          }, '');
        }else if (!!this.selected||_.size(selection)>0) {
          selection = !!this.selected ? this.selected : _.first(selection);
          message = this.$moment(selection.start).format('DD/MM HH')+'h';
        }
        return message;
      }
    },

    watch: {
      slots: {
        immediate: true,
        deep: true,
        handler (slots) {
          _.each(slots, (slot, s) => {
            if (this.first.day==null||this.$moment(this.first.day).isAfter(s)) this.first.day = s;
            if (this.$moment(this.last.day).isBefore(s)) this.last.day = s;

            const hours = _.keys(slot);
            const min = parseInt(_.minBy(hours, v => parseInt(v)));
            const max = parseInt(_.maxBy(hours, v => parseInt(v)));
            if (min < this.first.hour) this.first.hour = min;
            if (max > this.last.hour) this.last.hour = max;
          });
          this.span.slots = this.$moment(this.last.day).diff(this.first.day, 'd')+1;
          this.pointer = this.first.day;
          console.log(this.first.day, this.first.hour, this.last.day, this.last.hour);

          this.build(slots);
        }
      },
      selected: {
        immediate: true,
        handler (slot) {
          if (!!slot) {
            let key = this.$moment(slot.start);
            key = key.format('YYYY-MM-DD') + ' ' + key.hour();
            this.toggleView('calendar', false);
            this.toggleView('selection', true);
            this.toggleConfirm(false);
          }else{
            this.build(this.slots);
            this.toggleView('calendar', true);
            this.toggleView('selection', false);
            this.toggleCancel(false);
          }
        }
      },
      loading: function (b) {
        this.confirm.loading = b;
        this.cancel.loading = b;
        if (!b&&this.views.calendar.updating) {
          this.toggleView('calendar', true);
          this.views.calendar.updating = false;
        }
      }
    },

    methods: {
      confirmSlot (key, slot) {
        const disabled = slot.disabled;
        if (disabled) {
          if (_.has(slot, 'alert')&&slot.alert) this.toggleAlert(slot.alert);
          return;
        }
        const b = !slot.selected;
        console.log(key);
        if (this.multiple) {
          this.controller[key].selected = b;
          this.confirm.button = !_.isNil(_.find(this.controller, 'selected'));
        }else{
          this.controller[key].selected = b;
          this.toggleConfirm(b);
        }
      },
      build (slots) {
        let controller = {};
        _.each(this.hAxis, d => {
          _.each(this.vAxis, h => {
            const key = d+' '+h;
            controller[key] = {
              id: key,
              disabled: false,
              selected: false,
            }
          })
        });
        controller = _.mapValues(controller, (slot, key) => {
          // console.log(key);
          const [d, h] = _.split(key, ' ');
          slot = _.merge(slot, (_.has(slots[d], h) ? _.clone(slots[d][h]) : { disabled: true }));
          slot.color = _.has(slot, 'color') ? slot.color : 'white';
          // slot.selected = !!this.selected&&key
          return slot;
        })
        this.controller = controller;
      },
      selectSlots () {
        const data = _.filter(this.controller, 'selected');
        this.$emit('select', this.multiple ? data : _.first(data));
      },
      cancelSlot () {
        this.$emit('cancel');
      },
      refreshSlots () {
        this.$emit('refresh');
      },
      isAvailable (day, hour) {
        return _.has(this.slots, day) && _.has(this.slots[day], hour) && (!this.multiple||(_.has(this.slots[day][hour], 'disabled')&&!this.slots[day][hour].disabled));
      },
      nextDay () {
        const last = this.$moment(this.pointer).add(this.span.view, 'd');
        this.pointer = this.$moment(last).isAfter(this.last.day) ? this.$moment(this.last).subtract(this.span.view, 'd') : last;
      },
      prevDay () {
        this.pointer = this.$moment(this.pointer).subtract(1, 'days');
      },
      toggleView (view, b) {
        this.views[view].toggle = b;
      },
      toggleConfirm (b) {
        if (!this.multiple) {
          const selected = _.find(this.controller, 'selected');
          if (!b&&!_.isNil(selected)) selected.selected = false;
        }
        this.confirm.toggle = b;
      },
      toggleCancel (b) {
        this.cancel.toggle = b;
      },
      changeSelection () {
        this.views.calendar.updating = true;
        this.refreshSlots();
      },
      toggleAlert (msg) {
        this.$emit('toggle-alert', msg);
      },

      onScrollCalendar (e) {
        if (this.grid) {
          console.log(e.target.scrollTop);
          this.views.calendar.scroll.top = e.target.scrollTop;
          if (_.has(this.$refs, 'calendar-scroll')) {
            this.$refs['grid-hint'].scrollTop = this.views.calendar.scroll.top;
          }
        }
      },

      setCalendarWidth () {
        const gridWidth = _.has(this.$refs, 'calendar-grid') ? this.$refs['calendar-grid'].clientWidth : Infinity;
        const width = (((gridWidth / this.span.view) - 4) * this.span.slots);
        console.log(width);
        this.views.calendar.width = width > gridWidth ? width + 'px' : '100%';
        if (!this.views.calendar.shown&&_.has(this.$refs, 'calendar-grid')) {
          this.$refs['calendar-grid'].scrollTo(0,0);
          this.views.calendar.shown = true;
        }
      }
    },

    filters: {
      getWeekday (d) {
        return moment(d).format('ddd');
      },
      getSimpleDay (d) {
        return moment(d).format('DD');
      },
      // readableDate (date) {
      //   // console.log(date);
      //   date = _.isString(date) ? [date] : date;
      //   date = _.map(date, d => {
      //     d = moment(d);
      //     return d.format('DD/MM') + ' às ' + d.hour() +'h'
      //   })
      //   return _.join(date, ', ');
      // }
    },

    mounted () {
      this.setCalendarWidth();
      this.pointer = moment().format('YYYY-MM-DD');
      this.last.day = moment().format('YYYY-MM-DD');
    },
    updated () {
      // this.setCalendarWidth();
    }
  }
</script>